import React from 'react'

// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Userlist = React.lazy(() => import('./views/user/Userlist'))
const FeedBack = React.lazy(() => import('./views/user/feedback'))
const MyProfile = React.lazy(() => import('./views/user/Myprofile'))

const routes = [
  { path: '/users', name: 'Users', element: Userlist },
  { path: '/feedback', name: 'Feedbacks', element: FeedBack },
  { path: '/profile', name: 'My profile', element: MyProfile },
  { path: '*', name: 'page404' },
]

export default routes
