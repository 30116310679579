import axios from 'axios';
import { BASE_URL } from './base_url';
import { SharedService } from './shared';

const sharedService = new SharedService();

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (!config.url.endsWith('/login')) {
      try {
        const userData = await sharedService.getUserDetails();

        if (userData) {
          const token = userData.token;
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } else {
          localStorage.clear();
          window.location.href = '/';
          return Promise.reject('User data not found');
        }
      } catch (error) {
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject('Error fetching user details');
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMsg = 'Server encountered some error! Please try again';
    console.info(error);

    if (error.response) {
      if (error.response.data instanceof ErrorEvent) {
        errorMsg = `Error: ${error.response.data.message}`;
      } else {
        errorMsg = error.response.data.message || 'Network Error';

        if (error.response.status === 401) {
          if (!error.response.data.path.endsWith('/login')) {
            localStorage.clear();
            window.location.href = '/';
            errorMsg = 'Your session has expired. Please login';
            alert(errorMsg);
          } else {
            return Promise.reject(error.response);
          }
        }

        if (error.response.status === 500) {
          console.log(error);
          // window.location.href = '/500';
        }

        return Promise.reject(error.response);
      }
    } else {
      window.location.href = '/500';
    }

    return Promise.reject(error.response);
  }
);

const isTokenExpired = (token) => {
  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    return decodedToken.exp * 1000 < Date.now();
  } catch (error) {
    return true;
  }
};

const logout = async () => {
  sharedService.logout();
  localStorage.clear();
};

export default axiosInstance;
