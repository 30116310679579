import { useColorModes } from '@coreui/react';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from './components/loadingSpinner/Loading';
import ErrorBoundary from './errorBoundary';
import DefaultLayout from './layout/DefaultLayout';
import ProtectedRoutes from './protectedRoute';
import './scss/style.scss';
import Login from './views/pages/login/Login';
import Page404 from './views/pages/page404/Page404';
import Page500 from './views/pages/page500/Page500';
import PageError from './views/pages/pageError/pageError';

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes(
    'coreui-free-react-admin-template-theme'
  );
  const storedTheme = useSelector((state) => state.theme);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const theme =
      urlParams.get('theme') &&
      urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
  }, []);
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <Loading />
            </div>
          }
        >
          <Routes>
            <Route exact path="/" name="Login" element={<Login />} />
            <Route
              exact
              path="/error"
              name="Page 404"
              element={<PageError />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" element={<ProtectedRoutes />}>
              <Route path="*" name="User" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
