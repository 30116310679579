import React from 'react'
import {
  CButton,
  CCol,
  CContainer,
  CImage,
  CRow,
} from '@coreui/react'
import kudasLogo from '../../../assets/logo/kudas-logo.png';

const Page404 = () => {
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center ">
          <CImage style={{ width: '150px', height: '110px' }} src={kudasLogo}>
          </CImage>
          <CCol md={5}>
            <div className="clearfix mt-2">
              <h1 className="float-start display-3 me-4">404</h1>
              <h4 className="pt-3">Oops! You{"'"}re lost.</h4>
              <p className="text-body-secondary float-start">
                The page you are looking for was not found.
              </p>
             

            </div>
          </CCol>
       
        </CRow>
        <div className=' d-flex justify-content-center'>
          <CButton color="secondary error-back"  href='/users' className="mt-3" style={{ width: '20%', display: 'block' }}>Back to home</CButton>
        </div>
      </CContainer>
    </div>
  )
}
export default Page404
