import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilHome, cilSpeech, cilUser } from '@coreui/icons'
import { CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/users',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" size="lg" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    // hidden: useSelector(state => !state.auth.isAuthenticated),
  },
  {
    component: CNavItem,
    name: 'Feedbacks',
    to: '/feedback',
    icon: <CIcon icon={cilSpeech} customClassName="nav-icon" size="lg" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
]

export default _nav
