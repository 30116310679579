import React from 'react'
import { CSpinner } from '@coreui/react'


function Loading() {
  return (
    <>
      <div className="text-center overlay">
      <div className="spinner-container">
        <CSpinner color='primary' size='sm' className="spinner-loader"/>
      </div>
      </div>
    </>
  )
}

export default Loading