import { SharedService } from './shared'
const sharedService = new SharedService()
const userData = sharedService.getUserDetails()
const initialState = {
  token: userData.token || null,
  isAuthenticated: userData.token ? true : false,
  error: null,
  success: false,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
        error: null,
        success: true,
      }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        token: null,
        error: action.error,
        // isAuthenticated: false,
        success: false,
      }
    case 'LOGOUT':
      return {
        ...state,
        // isAuthenticated: false,
        token: null,
      }
    default:
      return state
  }
}

export default authReducer

export const isAuthenticated = () => {
  const token = userData.token
  // Customize this logic based on your token validation criteria
  return !!token // Return true if token exists, false otherwise
}
