import React from 'react'
import {
  cilUser
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom'

const AppHeaderDropdown = () => {
  return (
    <div variant="nav-item">
    </div>
  )
}
export default AppHeaderDropdown
