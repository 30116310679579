import {
  CButton,
  CCard,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { login } from '../../../action';
import kudasLogo from '../../../assets/logo/kudas-logo.png';
import Loading from '../../../components/loadingSpinner/Loading';
import { SharedService } from '../../../shared';

const sharedService = new SharedService();

const token = localStorage.getItem('accessToken');
const Login = () => {
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      window.location.href = '/users';
    }
  }, [navigate]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('*Please enter valid username'),
    password: Yup.string()
      .min(6, '*Password must be at least 6 characters long')
      .required('*Please enter valid password'),
  });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    setLoadingSpinner(true);
    const result = await dispatch(login(values.username, values.password));

    if (result.success) {
      navigate('/users');
      setSubmitting(false);
      setLoadingSpinner(false);
    } else {
      setLoadingSpinner(false);
      setSubmitting(false);
      const { field, message } = result.error;
      setFieldError(field, message);
    }
  };

  return (
    <>
      {loadingSpinner ? <Loading /> : ''}
      <div className="min-vh-100 d-flex align-items-center p-5">
        <CContainer style={{ width: 500 }}>
          <CRow className="justify-content-center">
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <CCard className=" shadow p-5 mt-5 mx-auto">
                    <p className="login_profile mb-3 text-center fw-bolder">
                      <CImage
                        style={{ width: '70px', height: '60px' }}
                        src={kudasLogo}
                        alt="Kudas Logo"
                      />{' '}
                      KudasAI: Admin Login
                    </p>
                    <CRow className="mb-3">
                      <CFormLabel htmlFor="username" className="col-form-label">
                        Username
                      </CFormLabel>
                      <CCol>
                        <Field
                          type="text"
                          name="username"
                          className="form-control"
                          placeholder="Enter your username"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CFormLabel htmlFor="password" className="col-form-label">
                        Password
                      </CFormLabel>
                      <CCol>
                        <Field
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter your password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>
                    </CRow>

                    <CButton
                      type="submit"
                      className=" mt-3 px-4 Login_button"
                      disabled={isSubmitting}
                    >
                      {/* {isSubmitting ? <CSpinner size="sm" style={{ color: '#cf5a3a' }} /> : 'Login'} */}
                      Login
                    </CButton>
                  </CCard>
                </Form>
              )}
            </Formik>
          </CRow>
        </CContainer>
      </div>
    </>
  );
};

export default Login;
