import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CDropdown,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu, cilAccountLogout, cilUser } from '@coreui/icons'
import { Link } from 'react-router-dom'
import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { useLocation } from 'react-router-dom'
import { logout } from '../action'
import { SharedService } from '../shared'

const AppHeader = () => {
  const sharedService = useMemo(() => new SharedService(), [])
  const headerRef = useRef()
  const [username, setUsername] = useState([])
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const handleLogout = () => {
    dispatch(logout())
  }
  const currentLocation = useLocation().pathname
  useEffect(() => {
    const getCurrentUser = async () => {
      const user = await sharedService.getUserDetails()
      setUsername(user.name)
    }
    getCurrentUser()
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0)
    })
  }, [])

  return (
    <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
      <CContainer className="border-bottom px-3" fluid>
        <CHeaderToggler
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          style={{ marginInlineStart: '-14px' }}
        >
          {/* <CIcon icon={cilMenu} size="lg" /> */}
        </CHeaderToggler>

        <CHeaderNav>
          {/* <Link to="/profile">
            <CIcon icon={cilUser} size='lg' />
          </Link> */}
          <CDropdown className="ms-2" style={{ border: 'none', width: '160px' }}>
            <CDropdownToggle>
              <CIcon icon={cilUser} size="xl" />
              <span className="ms-2">{username}</span>
            </CDropdownToggle>
            <CDropdownMenu className="dorpdown_menu">
              <CDropdownItem href="/profile">Profile</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>

          {/* <Link to="/" onClick={handleLogout}>
            <CIcon className='ms-2' icon={cilAccountLogout} size='lg' />
          </Link> */}
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {currentLocation !== '/users' && (
        <CContainer className="px-4" fluid>
          <AppBreadcrumb />
        </CContainer>
      )}
    </CHeader>
  )
}

export default AppHeader
