import axiosInstance from './axiosInstance';
import { BASE_URL } from './base_url';
import { SharedService } from './shared';
const sharedService = new SharedService();
export const login = (username, password) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}/authentication/login`,
        {
          username,
          password,
        }
      );

      if (response.status === 200 && response.data && response.data.data) {
        if (
          response.data.data.roleId == '2' ||
          response.data.data.roleId == '1'
        ) {
          const { token } = response.data.data;
          localStorage.setItem('accessToken', token);
          sharedService.saveUserDetails(response.data.data);

          dispatch({ type: 'LOGIN_SUCCESS', token });
          return { success: true, data: response.data.data };
        } else {
          alert('You are not authorized to perform the action');
          return {
            success: false,
            data: 'You are not authorized to perform the action',
          };
        }
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      let errorMessage = error.data?.message || error.Error || 'Login failed';
      let fieldError = 'username';

      if (errorMessage.toLowerCase().includes('password')) {
        errorMessage = 'Incorrect password';
        fieldError = 'password';
      }

      dispatch({ type: 'LOGIN_FAILURE', error: errorMessage });
      return {
        success: false,
        error: { message: errorMessage, field: fieldError },
      };
    }
  };
};

export const logout = () => {
  localStorage.clear();
  sharedService.logout();
  return (dispatch) => {
    dispatch({ type: 'LOGOUT' });
  };
};
