import {
  CButton,
  CCloseButton,
  CImage,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
} from '@coreui/react';
import Cookies from 'js-cookie';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import kudasLogo from '../assets/logo/kudas-logo.png';
import { AppSidebarNav } from './AppSidebarNav';

// sidebar nav config
import navigation from '../_nav';

const AppSidebar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
    dispatch(logout());
  };
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const logout = () => {
    Cookies.set('currentUser', '');
  };
  return (
    <CSidebar
      className="border-end side-nav"
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible });
      }}
    >
      <CSidebarHeader>
        <CSidebarBrand
          className="d-flex justify-content-center"
          style={{ textDecoration: 'none' }}
        >
          <CImage width={35} src={kudasLogo} alt="no image" />
          <span className="side-nav-head">Kudas AI</span>
        </CSidebarBrand>
        <CCloseButton
          className="d-lg-none"
          light="on"
          onClick={() => dispatch({ type: 'set', sidebarShow: false })}
        />
      </CSidebarHeader>
      <AppSidebarNav items={navigation} />
      <CSidebarFooter className="border-top d-none d-lg-flex">
        <CButton onClick={handleLogout} className="side-nav-bottomHead">
          Log out
        </CButton>

        {/* <CSidebarToggler   onClick={handleLogout} 
          // onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
          
        /> */}
        {/* <span style={{ fontSize: "20px" , marginLeft:'5px'}}>Log out</span> */}
      </CSidebarFooter>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
