import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
const ENCRYPTION_KEY = 'your-encryption-key';
export class SharedService {

  saveUserDetails = async (user) => {
    let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(user), ENCRYPTION_KEY).toString();
    Cookies.set('currentUser', encryptedData);
    localStorage.setItem('user', encryptedData);
  }

  getUserDetails = async () => {
    let currentUser = null;
    let encryptedData = localStorage.getItem('user');
    if (encryptedData && encryptedData != '') {
      const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
      currentUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    return currentUser;
  }

  getCurrentUserFromCookies = async (encryptedData) => {
    let currentUser = null;
    if (encryptedData && encryptedData != '') {
      currentUser = await this.decrypt(encryptedData);
      currentUser = JSON.parse(currentUser)
    }
    return currentUser;
  }

  logout = () => {
    window.location.href = '/'
    localStorage.setItem('user', '');
    Cookies.set("currentUser", '');
  }
}
