import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { SharedService } from './shared';

const sharedService = new SharedService();

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const userData = await sharedService.getUserDetails();
        if (userData) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          localStorage.clear();
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
        localStorage.clear();
        navigate('/');
      }
    };

    checkAuthentication();
  }, [navigate]);

  if (isAuthenticated === null) {
    // return <div><Loading /> </div>;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
