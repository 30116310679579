import React from 'react'
import {
  CCol,
  CContainer,
  CRow,
  CImage,
  CButton
} from '@coreui/react'
import kudasLogo from '../../../assets/logo/kudas-logo.png';

const PageError = () => {
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <div className='' style={{ textAlign: 'center' }}>
            <CImage style={{ width: '150px', height: '110px' }} src={kudasLogo}>
            </CImage>
          </div>
          <CCol md={5}>
            <div className="clearfix mt-2" style={{ textAlign: 'center' }}>
              <h4 className="pt-2">we have a problem!</h4>
              <p className="text-body-secondary float-start">
                The page you are looking for is temporarily unavailable.
              </p>

            </div>
          </CCol>
        </CRow>
        <div className=' d-flex justify-content-center'>
          <CButton color="secondary error-back" href='/users' className="mt-3" style={{ width: '20%', display: 'block' }}>Back to home</CButton>
        </div>
      </CContainer>
    </div>
  )
}

export default PageError
